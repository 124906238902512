import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/BlogLayout/index.jsx";
import BlogPostMetaData from "../../../../components/BlogPostMetaData";
import TCCOutboundLink from "../../../../components/TCCOutboundLink";
export const pageMetaData = {
  pageTitle: "Things To Consider When Looking For Health Insurance",
  pageStrapLine: "Things to consider when shopping for new Health Insurance",
  pageImagePath: "/20201-03-23-health-insurance.jpg",
  pageDescription: "We explain some of the terms used by Health Insurers so you know what to consider when shopping for Health Insurance",
  pageMetaTags: []
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Things To Consider When Looking For Health Insurance`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/44dc54e949f417de57ae86de2f51bc7a/80e3c/20201-03-23-health-insurance.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHRSEFz/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQABBQJGxCn/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAABECD/2gAIAQEABj8Cgr//xAAZEAEBAQADAAAAAAAAAAAAAAABABEQIaH/2gAIAQEAAT8hMJrEc6mU/XH/2gAMAwEAAgADAAAAEOs//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EDGf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EDV//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFB/9oACAEBAAE/EAezpU2rxg2MTBAGygrkW2f/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Things To Consider When Looking For Health Insurance",
            "title": "Things To Consider When Looking For Health Insurance",
            "src": "/static/44dc54e949f417de57ae86de2f51bc7a/80e3c/20201-03-23-health-insurance.jpg",
            "srcSet": ["/static/44dc54e949f417de57ae86de2f51bc7a/f93b5/20201-03-23-health-insurance.jpg 300w", "/static/44dc54e949f417de57ae86de2f51bc7a/b4294/20201-03-23-health-insurance.jpg 600w", "/static/44dc54e949f417de57ae86de2f51bc7a/80e3c/20201-03-23-health-insurance.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <BlogPostMetaData postDate={props.pageContext.frontmatter.date} category={props.pageContext.frontmatter.blogCategory} mdxType="BlogPostMetaData" />
    <p>{`Buying health insurance, whether it is for yourself, your family, or your business, can seem overwhelming at first. Although, like most things, once you know what to look for, it's not all that complicated. There are some nuances you of course want to keep an eye on and price is always a factor, one that can often be hard to balance in the initial set-up of a plan.  But once you decide on how much per month you can afford, and what you want on your plan to cover, then you're halfway there.`}</p>
    <p>{`More often consumers find themselves in the battle of monthly premiums in comparison to the right insurance coverage; and though focusing on cost is important, it's not the only factor to take into account in the long run.`}</p>
    <p>{`So what else should you consider when shopping for health insurance? Below is some basic, but essential information you'll want to remember when diving into the confusing waters of coverage ranges, terminology, and pricing.`}</p>
    <h3>{`What Is A Premium?`}</h3>
    <p>{`A premium is the total cost of the health insurance plan that you, the consumer, will pay every month, with some options to pay it in full for the year. The bottom line cost is determined by the benefits included in the chosen health plans coverage.`}</p>
    <p><em parentName="p">{`Take note: this amount is separate from the deductible and copay. A way to keep your monthly costs low and save money is in the details of your deductible, copay, and coinsurance amounts.`}</em></p>
    <h3>{`What Is The Deductible?`}</h3>
    <p>{`The deductible is the set dollar amount that a consumer needs to pay BEFORE the insurance will pay for most services.`}</p>
    <p>{`That means that if you have a hospital bill for $5,000, but a deductible of $1,000 for that specific hospital visit; you as the policyholder pay the $1,000 out of pocket, while the insurance covers the other $4,000 through the monthly premium you’ve been paying into every month.`}</p>
    <p><em parentName="p">{`Take note: plans which have higher deductibles are beneficial to individuals who rarely visit the doctor or utilize prescription medications. This means those who visit the doctor often and have pre-existing conditions would benefit from a plan that has lower deductibles and higher monthly premiums, or payments.`}</em></p>
    <h3>{`What Is Copay?`}</h3>
    <p>{`This is the cost the consumer pays, on top of the monthly premium, and in addition to deductibles, anytime they go to a doctor or health specialist. Copays can also apply to prescription drugs, emergency room visits, and even urgent care.  Usually showing up in a flat fee of $20-$40 a doctor and/or specialist visit.`}</p>
    <p><em parentName="p">{`Take note: copays are their own entity, they do not go towards your deductible or premium.`}</em></p>
    <h3>{`What Is Coinsurance?`}</h3>
    <p>{`This is the percentage you as the consumer are responsible for paying AFTER the insurance company handles their part. This varies based on benefits and is only done once your deductible is met.`}</p>
    <p>{`For example, if you have a hospital bill for $1,000, but a policy deductible of $5,000, that you haven't met yet through monthly payments. Then you would owe the full $1,000. However, if you have met your policy deductible, then you'll only owe a percentage of your bill through coinsurance. So if you have met your deductible and your coinsurance is 20%, then you would pay $200, and the insurance company would pay the other $800.`}</p>
    <p><em parentName="p">{`Take note: Your policy should show you your coinsurance percentage as a fraction like 70/30, 50/50, or 80/20. The latter fraction is the most common rate.`}</em></p>
    <h3>{`What insurance is the best fit for your needs?`}</h3>
    <p>{`When looking for insurance keep in mind that not all policies or companies are built the same. What may work for you, might not work for the family next door. So having a real sit down to assess your medical needs and health layout before looking into insurance can help save thousands in the long run.`}</p>
    <p>{`There is no ‘set’ right plan for everyone, just like our unique needs, plans have their unique layouts. This is why there are so many health insurance options on the market. If finding the right coverage is still daunting, sites like `}<a parentName="p" {...{
        "href": "/"
      }}>{`The Comparison Company`}</a>{` are recommended when helping you compare health insurance’s benefits and costs in relation to your needs.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      